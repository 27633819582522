
import { defineComponent, reactive, toRefs, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { ContentLoader } from 'vue-content-loader';
import OrcamentosFinalizadosCard from "./components/OrcamentosFinalizadosCard.vue";
import { getOficinaDigitalConsultores, getOficinaDigitalHistoricoOrcamentos } from "@/services/OficinaDigital.ts";
import useEmitter from '@/composables/Emmiter';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import moment from 'moment';
import store from "@/store/index";
import useAlert from "@/composables/Alert";
import XLSX from 'xlsx-js-style';

export default {
	name: "ODHistoricoOrcamentos",
	components: {
		OrcamentosFinalizadosCard,
		ContentLoader
	},

    setup() {
		const { showTimeAlert } = useAlert();
		const emptyArray: Array<any> = [];
		const emitter = useEmitter();
		// const route = useRoute();
        let dadosChecklist = ref({});
        let loader = ref(false);
		const router = useRouter();

        //filtros
		const codConcessionaria = ref("");
		const loading = ref(true);
		const mostrarSomenteSelected = ref("Todos");
		const dataOrcamentos:any = ref([]);
		const dataOrcamentosFiltratos:any = ref([]);
		const dataOrcamentosFiltratosToXLSX:any = ref([]);
        
		function goTo(verificador){
			let url = "/#/";

			if(verificador == "voltar"){
				url = `/oficina-digital/finalizados`;
			}else if(verificador == "historico"){
				url = `/oficina-digital/${codConcessionaria.value}/historico`;
			}

			router.push(url);
		};

		emitter.on("filtrar-od-historico", (filtroInfo) => {
			codConcessionaria.value = filtroInfo.codConcessionaria;
      		getOrcamentos(filtroInfo.postData);
    	});

		async function getOrcamentos(postData){
			loading.value = true;
			try {
				const response = await getOficinaDigitalHistoricoOrcamentos(codConcessionaria.value, postData);
				dadosChecklist.value = response.dadosChecklist;

				let checklists = response.checklists;
				checklists.forEach(checklist => {
					checklist.codChecklist = checklist.codCheckList;
					checklist.dataEntradaTratada = moment(checklist.dataEntrada, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
					if(checklist.qtdAprovados > 0 && checklist.qtdReprovados == 0){
						checklist.tipoOrcamento = 1;
					} else if (checklist.qtdAprovados > 0 && checklist.qtdReprovados > 0) {
						checklist.tipoOrcamento = 2;
					} else if (checklist.qtdAprovados == 0 && checklist.qtdReprovados > 0) {
						checklist.tipoOrcamento = 3;
					} else if (checklist.qtdAprovados == 0 && checklist.qtdReprovados == 0) {
						checklist.tipoOrcamento = 4;
					}
				});
				dataOrcamentos.value = checklists ?? [];
				mostrarSomente();
			} catch (e){
				showTimeAlert('Não foi possível completar a solicitação', "error");
				dataOrcamentos.value = [];
			} finally {
				loading.value = false;
			}
		};

		let blob = ref();

		emitter.on("geraXLSX", () => {
			gerarXLSX();
		});

		emitter.on("exportXLSX", () => {
			exportarXLSX();
		});

		function gerarXLSX() {
			let wb: XLSX.WorkBook = XLSX.utils.book_new();
			wb.Props = {
				Title: "SheetJS Tutorial",
				Subject: "Test",
				Author: "Red Stapler",
				CreatedDate: new Date(2017,12,19)
			};
			wb.SheetNames.push("MO");

			const ws = XLSX.utils.json_to_sheet(dataOrcamentosFiltratosToXLSX.value.length == 0 ? [
				{
					"Cliente": "",
					"Placa": "",
					"Modelo": "",
					"E-mail": "",
					"Telefone": "",
					"Consultor": "",
					"Mecânico": "",
					"Data": "",
					"Itens Aprovados": "",
					"Itens Reprovados": "",
				}
			] : dataOrcamentosFiltratosToXLSX.value);
			const wscols = [
				{wch:45},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
			]
			ws['!cols'] = wscols;

			ws["A1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
			ws["B1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
			ws["C1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
			ws["D1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
			ws["E1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
			ws["F1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
			ws["G1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
			ws["H1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
			ws["I1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}
			ws["J1"].s = {
				fill: {
				fgColor: { rgb: "FFD6D8DB" },
				},
			}

			wb.Sheets["MO"] = ws;

			function s2ab(s) {  
				var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
				var view = new Uint8Array(buf);  //create uint8array as viewer
				for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
				return buf;    
			}

			var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

			blob.value = new Blob([s2ab(wbout)], {type: 'text/plain;charset=UTF-8'});
		};

		function exportarXLSX(){
			const link = document.createElement('a');
			if (link.download !== undefined) {
				const url = URL.createObjectURL(blob.value);
				link.setAttribute('href', url);
				link.setAttribute('download', "export_historico_orcamento.xlsx");
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);  
			};
		};

		function mostrarSomente(){
			const enumTipo = [
				"Todos",
				"Aprovados",
				"Parcialmente aprovados",
				"Reprovados",
				"Link não enviado",
			];

			if(mostrarSomenteSelected.value == "Todos" || mostrarSomenteSelected.value == ""){
				dataOrcamentosFiltratos.value = [...dataOrcamentos.value]; 
 			} else {
				dataOrcamentosFiltratos.value = [...dataOrcamentos.value].filter(orcamento => {
					const refEnum = enumTipo.findIndex(tipo => tipo == mostrarSomenteSelected.value);
					return orcamento.tipoOrcamento === refEnum;
				});
			}

			dataOrcamentosFiltratosToXLSX.value = dataOrcamentosFiltratos.value.map(orcamento => {
				return {
					"Cliente": orcamento.cliente,
					"Placa": orcamento.placa,
					"Modelo": orcamento.modelo,
					"E-mail": orcamento.email,
					"Telefone": orcamento.telefone,
					"Consultor": orcamento.nomeConsultor,
					"Mecânico": orcamento.nomeMecanico,
					"Data": orcamento.dataEntrada,
					"Itens Aprovados": orcamento.servicos.filter(servico => servico.situacao == "aprovado").map((servico, index) => `${index + 1}) ${servico.item}`).join("\r\n"),
					"Itens Reprovados": orcamento.servicos.filter(servico => servico.situacao == "reprovado").map((servico, index) => `${index + 1}) ${servico.item}`).join("\r\n"),
				}
			});
		}

        return {
			dadosChecklist,
			loading,
			dataOrcamentosFiltratos,
			dataOrcamentos,
            loader, //variavel para o carregamento da pagina
            mostrarSomenteSelected,
			ptBr, //deixar o calendario em portugues
            goTo, //funcao para rotas
			mostrarSomente, //funcao para alterar os dados de exibição
		}
    }
};
